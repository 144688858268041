import PropTypes from 'prop-types';
import React from 'react';
import {InputDiv, InputLabelDiv, InputField} from './InputStyles';

const TagInput = ({
  input,
  meta,
  prefix,
  size,
  suffix,
  type,
  small,
  onPressEnter,
  label,
  first,
  placeholder,
  disabled
}) => {
  const {invalid, submitFailed} = meta;
  const showError = invalid && submitFailed;

  const onBlur = () => {
    if (input.value && input.value !== '' && typeof input.value === 'string') {
      let result = input.value;
      result = result.toUpperCase();
      //result = result.replace(' ', '_');
      input.onChange(result);
    }
  };

  return (
    <InputDiv first={first}>
      <InputLabelDiv small={small}>{label}</InputLabelDiv>
      <InputField
        disabled={disabled}
        prefix={prefix}
        size={size}
        suffix={suffix}
        type={type}
        onBlur={onBlur}
        value={input.value}
        onChange={input.onChange}
        // onChange={(e) => {
        //   const newValue = e.target.value.replace(/\s+/g, '_'); 
        //   input.onChange(newValue);
        // }}
        placeholder={placeholder}
        error={showError ? 1 : 0}
      />
    </InputDiv>
  );
};

TagInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default TagInput;
