import React, { Component, Fragment } from 'react';
import { Avatar, Button, Dropdown, Icon, Menu, message, Modal, Pagination, Popconfirm, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
  SectionTitle
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { VenueColors, getInitials, getLocalColor, getWeddingContacts, getWeddingEmails, getWeddingName, getWeddingStatus } from '../../infra/services/wedding/weddingUtils';
import { getUserId, isCurrentUserAdmin, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import AppointmentFilters from './SchedulesFilters';
import { CheckMngMultipleByAttending, ConfirmReserve, DownloadReserves, GetReservesPaginated, GetReservesPaginatedAndGrouped, ReactivateReserve, SoftDeleteReserve, TimeForEmployeeAttending, UpdateReserveConfirmationDate, UpdateReserveRelatedInfo, UploadReservesToDrive } from '../../infra/requests/ReserveRequests';
import Alerts from '../../components/alert/Alert';
import { GetColorSystemByType, GetVenueColors } from '../../infra/requests/ColorSystemRequests';
import styledComponents from 'styled-components';
import ReserveInfoModal from '../reservesAttending/ReserveInfoModal';
import { GetTextColor } from '../../infra/services/text/textUtils';
import momentTimezone from 'moment-timezone';
import { AuthGoogleDrive } from '../../infra/requests/ReportsRequests';
import AttendingApointmentFilters from './AttendingSchedulesFilters';
import { ExtendReserveEditingTime, UpdateReserveNotes } from '../../infra/requests/WeddingRequests';
import AttendingSchedulesFilters from './AttendingSchedulesFilters';
import SchedulesFilters from './SchedulesFilters';
import { WeddingStatus } from '../reserves/ReserveAgenda';
import { CreateInternalSchedule, CreateOrganizationSchedule, CreateSchedule, DownloadSchedules, DownloadSchedulesAvailables, GetSchedulesPaginated, RegisterSchedulePresence, SoftDeleteSchedule, UploadSchedulesToDrive } from '../../infra/requests/SchedulesRequests';
import ReserveNotesModal from '../reserves/ReserveNotesModal';
import AccessGuestModal from '../weddings/AccessGuestModal';
import { GetEmployeeByUser } from '../../infra/requests/EmployeeRequests';
import SchedulesPageDetail from './MeetingsPageDetail';
import ChangeSizerInput from '../../components/inputs/ChangeSizerInput';
import { inactiveColor } from '../reserves/reservesUtils';
import { CreateTotalUnavailable, CreateUnavailable, RemoveTotalUnavailableByAgenda, RemoveUnavailableByAgenda } from '../../infra/requests/UnavailableRequests';
import UnavailableModal from './UnavailableModal';
import AddUnavailableIcon from '../../assets/icons/create_unavailable.svg';
import RemoveUnavailableIcon from '../../assets/icons/remove_unavailable.svg';
import ScheduleInternalModal from './ScheduleInternalModal';
import TableExpanded from '../../components/table/TableExpanded';
import ReactSVG from 'react-svg';
import { CreateEditScheduleNotes } from '../../infra/requests/ScheduleNotesRequests';
import ScheduleNotesModal from './ScheduleNotesModal';
import VisitsPageDetail from './VisitsPageDetail';
import MeetingsPageDetail from './MeetingsPageDetail';
import { DateColumn, SpanNotes } from './ScheduleStyles';
import VisitModal from './VisitModal';
import MeetingModal from './MeetingModal';
import { ScheduleTypes } from './utilSchedules';
import { FakePagination } from '../../components/table/TableStyles';
const { confirm } = Modal;

class SchedulesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    download: false,
    upload: false,

    organizerMeetings: false,
    filters: {},
    needRefresh: false,

    columnsAttending: [
      {
        title: 'Data',
        key: 'date',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: data?.companyRest?.both ? inactiveColor : 'inherit',
                color: GetTextColor(data?.companyRest?.both ? inactiveColor : null)
              }
            },
            children: data?.date ?
              <DateColumn>
                <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
              </DateColumn>
              : ''
          };
        }
      },
      {
        title: 'Entrada SLE',
        key: 'arrivingSolar',
        width: '80px',
        render: data => {
          const bgColor = data?.companyRest?.both || data?.companyRest?.solar
            ? inactiveColor
            : data?.weddings?.solar
              ? this.state.colors.solar
              : null;
          return {
            props: {
              style: {
                backgroundColor: bgColor || 'inherit',
                color: GetTextColor(bgColor)
              }
            },
            children: data?.companyRest?.both || data?.companyRest?.solar
              ? 'Indisponível SLE'
              : data?.weddings?.solar && data?.weddings?.arrivingSolar
                ? data?.weddings?.arrivingSolar
                : 'SLE'
          };
        }
      },
      {
        title: 'Entrada QLC',
        key: 'arrivingLago',
        width: '80px',
        render: data => {
          const bgColor = data?.companyRest?.both || data?.companyRest?.lago
            ? inactiveColor
            : data?.weddings?.lago
              ? this.state.colors.lago
              : null;
          return {
            props: {
              style: {
                backgroundColor: bgColor || 'inherit',
                color: GetTextColor(bgColor)
              }
            },
            children: data?.companyRest?.both || data?.companyRest?.lago
              ? 'Indisponível QLC'
              : data?.weddings?.lago && data?.weddings?.arrivingLago
                ? data?.weddings?.arrivingLago
                : 'QLC'
          }
        }
      },
      {
        title: 'Notas',
        key: 'notes',
        width: '150px',
        render: data => {
          const bgColor = data?.companyRest?.both || data?.companyRest?.lago
            ? inactiveColor
            : data?.weddings?.lago
              ? this.state.colors.lago
              : null;

          return {
            props: {
              style: {
                backgroundColor: bgColor || 'inherit',
                color: GetTextColor(bgColor)
              }
            },
            children: <SpanNotes
              dangerouslySetInnerHTML={{
                __html: (data?.notes?.content || '')
              }}>
            </SpanNotes>
          };
        }
      },
    ],
    columns: [
      {
        title: 'Data',
        key: 'date',
        // width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: data?.companyRest?.both ? inactiveColor : 'inherit',
                color: GetTextColor(data?.companyRest?.both ? inactiveColor : null)
              }
            },
            children: data?.date ?
              <DateColumn>
                <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
              </DateColumn>
              : ''
          };
        }
      },
      {
        title: 'Entrada SLE',
        key: 'arrivingSolar',
        // width: '80px',
        render: data => {
          const bgColor = data?.companyRest?.both || data?.companyRest?.solar
            ? inactiveColor
            : data?.weddings?.solar
              ? this.state.colors.solar
              : null;
          return {
            props: {
              style: {
                backgroundColor: bgColor || 'inherit',
                color: GetTextColor(bgColor)
              }
            },
            children: data?.companyRest?.both || data?.companyRest?.solar
              ? 'Indisponível SLE'
              : data?.weddings?.solar && data?.weddings?.arrivingSolar
                ? data?.weddings?.arrivingSolar
                : 'SLE'
          };
        }
      },
      {
        title: 'Entrada QLC',
        key: 'arrivingLago',
        // width: '80px',
        render: data => {
          const bgColor = data?.companyRest?.both || data?.companyRest?.lago
            ? inactiveColor
            : data?.weddings?.lago
              ? this.state.colors.lago
              : null;
          return {
            props: {
              style: {
                backgroundColor: bgColor || 'inherit',
                color: GetTextColor(bgColor)
              }
            },
            children: data?.companyRest?.both || data?.companyRest?.lago
              ? 'Indisponível QLC'
              : data?.weddings?.lago && data?.weddings?.arrivingLago
                ? data?.weddings?.arrivingLago
                : 'QLC'
          }
        }
      },
      {
        title: 'Notas',
        key: 'notes',
        width: '150px',
        render: data => {
          const { organizerMeetings } = this.state;
          return {
            props: {
              style: {
                backgroundColor: data?.companyRest?.both ? inactiveColor : 'inherit',
                color: GetTextColor(data?.companyRest?.both ? inactiveColor : null)
              }
            },
            children: <Tooltip title={'Editar'}>
              <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: organizerMeetings ? (data?.notes?.contentMeeting || '') : (data?.notes?.content || '')
                }}
                onClick={($event) => this.editScheduleNotes($event, data)}>
              </SpanNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Disponibilidade',
        key: 'availables',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: data?.companyRest?.both ? inactiveColor : 'inherit',
                color: GetTextColor(data?.companyRest?.both ? inactiveColor : null)
              }
            },
            children: <TableButton onClick={(e) => this.getAvailableList(e, data)}>
              <Icon type="unordered-list" />
              &nbsp;Obter lista
            </TableButton>
          };
        }
      },
      {
        title: 'Acções',
        key: 'action',
        width: '70px',
        render: data => {
          return {
            props: {
              style: {
                // backgroundColor: GetRowColor(this.state.colors, data),
                // color: GetTextColor(GetRowColor(this.state.colors, data))
              }
            },
            children: <Fragment>
              {!data?.companyRest?.both
                && <Tooltip title={this.state.organizerMeetings ? 'Criar reunião' : 'Criar visita'}>
                  <TableButton onClick={(e) => this.createSchedule(e, data)}>
                    <Icon type="plus-circle" />
                  </TableButton>
                </Tooltip>}
              {data?.companyRest && <Tooltip title={'Marcar local aberto'}>
                <TableButton onClick={(e) => this.showUnavailableModal(e, false, data)}>
                  <ReactSVG src={AddUnavailableIcon} />
                </TableButton>
              </Tooltip>
              }
              {!data?.companyRest?.both && <Tooltip title={'Marcar local fechado'}>
                <TableButton onClick={(e) => this.showUnavailableModal(e, true, data)}>
                  <ReactSVG src={RemoveUnavailableIcon} />
                </TableButton>
              </Tooltip>}
            </Fragment>
          };
        }
      }
    ],
    rows: [],
    total: 0,

    colors: VenueColors,

    loadingEmployee: false,
    employee: null,

    showMeetingModal: false,
    showVisitModal: false,
    valuesModal: {},
    savingModal: false,

    showInternalMeetingModal: false,
    savingInternalMeetingModal: false,

    showUnavailableModal: false,
    valuesUnavailableModal: {},
    savingUnavailableModal: false,

    showNotesModal: false,
    notesModal: null,
    savingNotes: false,

    exportingAvailableList: false,

    showChildModal: false,
  };

  componentDidMount = async () => {
    const { location } = this.props;

    this.setState({
      organizerMeetings: location?.pathname == '/meetings' ? true : false
    }, () => {
      this.getLocalColors();
      if (isCurrentUserEmployeeAttending()) this.getEmployee();
      this.updateDataTable();
    });

    setInterval(() => {
      const { showMeetingModal, showVisitModal, showInternalMeetingModal, showUnavailableModal, showNotesModal, exportingAvailableList, showChildModal } = this.state;

      if (!showMeetingModal
        && !showVisitModal
        && !showInternalMeetingModal
        && !showUnavailableModal
        && !showNotesModal
        && !exportingAvailableList
        && !showChildModal) {
        console.log("Hi, after 15 min");
        this.updateDataTable();
      }
    }, 900000);
  };

  handleOpenChildModal = (showChildModal) => {
    this.setState({ showChildModal });
  };

  getEmployee = async () => {
    this.setState({ loadingEmployee: true });
    const resEmployee = await GetEmployeeByUser(getUserId());
    const employeeInfo = resEmployee.success && resEmployee.data ? resEmployee.data : null;
    this.setState({ loadingEmployee: false, employee: employeeInfo });
  }

  getLocalColors = async () => {
    let { colors } = this.state;
    const result = await GetVenueColors();

    if (result?.success) colors = result?.data;
    this.setState({ colors });
  }

  copy = ($event, text) => {
    $event.stopPropagation();
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('Campo copiado!');
  }

  // Table
  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    const { organizerMeetings } = this.state;
    let { filters, loading } = this.state;
    if (loading) {
      this.setState({ needRefresh: true });
      return;
    }

    this.setState({ loading: true });
    try {
      const { currentPage, pageSize } = this.state;

      const filtersEnd = { ...filters, organizerMeetings };
      if (isCurrentUserEmployeeAttending()) {
        filtersEnd['attendedBy'] = getUserId();
        filtersEnd['employeeAttending'] = true;
      }

      // Ignore filter dates if array is empty
      if (Array.isArray(filtersEnd?.dates) && filtersEnd?.dates.length === 0) {
        delete filtersEnd.dates;
      }
      
      const result = await GetSchedulesPaginated(currentPage, pageSize, JSON.stringify(filtersEnd));

      this.setState({
        rows: result?.data?.items || [],
        //total: result?.data?.total || 0,
        total: result?.data?.items?.length || 0,
        //currentPage: result.data.page,
        pageSize: result?.data?.limit || 30,
        loading: false
      }, () => {
        if (this.state.needRefresh) {
          this.setState({ needRefresh: false }, () => this.updateDataTable());
        }
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };
  // End Table

  // Notes
  editScheduleNotes = async ($event, data) => {
    $event.stopPropagation();
    const { organizerMeetings } = this.state;

    this.setState({
      notesModal: {
        _id: data?.notes?._id,
        date: data?.date,
        organizerMeetings,
        notes: organizerMeetings ? data?.notes?.contentMeeting : data?.notes?.content,
      },
      showNotesModal: true
    });
  }

  onNotesModalSubmit = async (values) => {
    if (isCurrentUserEmployeeAttending()) {
      return;
    }

    this.setState({ savingNotes: true });

    const dataToUpdate = {
      _id: values?.id,
      date: values?.date,
    };
    if (values?.organizerMeetings) {
      dataToUpdate['contentMeeting'] = values?.notes;
    } else {
      dataToUpdate['content'] = values?.notes;
    }
    const { success, data } = await CreateEditScheduleNotes(dataToUpdate);

    if (success && data) {
      Alerts.new({
        type: 'success',
        title: 'Gravado com sucesso',
        text: 'A informação foi gravada com sucesso!'
      });

      this.setState({
        showNotesModal: false,
        notesModal: {},
        savingNotes: false
      }, () => this.updateDataTable());
    } else {
      this.setState({ savingNotes: false });
      return;
    }
  };

  // Available Employees
  getAvailableList = async ($event, data) => {
    $event.stopPropagation();

    const { organizerMeetings } = this.state;

    this.setState({
      exportingAvailableList: true,
      loading: true
    });

    const filters = {
      date: data?.date,
      organizerMeetings,
    };

    const result = await DownloadSchedulesAvailables(JSON.stringify(filters));

    if (result) {
      const fileTypeName = organizerMeetings
        ? 'Disponibilidades_Reunioes_'
        : 'Disponibilidades_Visitas_';
      let filename = `${fileTypeName}${moment().format('YYYYMMDD')}.xlsx`;

      if (result.headers) {
        const contentDisposition = result.headers.get('content-disposition');
        filename = contentDisposition.split('filename="')[1].split('"')[0];
      }

      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([result])); //result.blob
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      //Append to html link element page
      document.body.appendChild(link);

      //Start download
      link.click();

      //Clean up and remove the link
      link.parentNode.removeChild(link);

      this.setState({
        exportingAvailableList: false,
        loading: false
      });
    } else {
      this.setState({
        exportingAvailableList: false,
        loading: false
      });
    }
  }

  createSchedule = ($event, generalInfo) => {
    $event.stopPropagation();
    const { organizerMeetings } = this.state;
    if (generalInfo?.companyRest?.both) return;

    // Open modal with date based on generalInfo 
    const values = {
      date: generalInfo?.date,
      specific: true,
    };
    if (generalInfo?.companyRest?.solar) values['weddingPlace'] = 2;
    if (generalInfo?.companyRest?.lago) values['weddingPlace'] = 1;
    if (generalInfo?.companyRest?.solar || generalInfo?.companyRest?.lago) {
      values['specificPlace'] = true;
    }

    this.setState({
      showVisitModal: organizerMeetings ? false : true,
      showMeetingModal: organizerMeetings ? true : false,
      valuesModal: values
    });
  }

  submitSchedule = async (values) => {
    if (isCurrentUserEmployeeAttending()) return;

    this.setState({ savingModal: true });

    const data = { ...values };
    if (moment.utc(data?.startTime).isValid()) data.startTime = moment.utc(data?.startTime).format('HH:mm');
    const result = data?.type == ScheduleTypes.ORGANIZATION
      ? await CreateOrganizationSchedule(data)
      : await CreateSchedule(data);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: data?.type == ScheduleTypes.ORGANIZATION ? 'Reunião criada!' : 'Visita criada!'
      });
      this.setState({
        showMeetingModal: false,
        showVisitModal: false,
        valuesModal: false,
        savingModal: false
      }, () => this.updateDataTable());
    } else {
      this.setState({ savingModal: false });
    }
  }

  submitInternalSchedule = async (values) => {
    if (isCurrentUserEmployeeAttending()) return;

    this.setState({ savingInternalMeetingModal: true });

    const data = { ...values };
    if (moment.utc(data?.startTime).isValid()) data.startTime = moment.utc(data?.startTime).format('HH:mm');
    if (moment.utc(data?.endTime).isValid()) data.endTime = moment.utc(data?.endTime).format('HH:mm');
    const result = await CreateInternalSchedule(data);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Reunião interna criada!'
      });
      this.setState({
        showInternalMeetingModal: false,
        savingInternalMeetingModal: false
      }, () => this.updateDataTable());
    } else {
      this.setState({ savingInternalMeetingModal: false });
    }
  }

  /** Unavailable */
  showUnavailableModal = ($event, createAction, generalInfo) => {
    $event.stopPropagation();

    if (generalInfo?.companyRest?.both && createAction) return;
    if (!generalInfo?.companyRest && !createAction) return;

    // Open modal with date based on generalInfo 
    const values = {
      date: generalInfo?.date,
      createAction
    };

    if (createAction && generalInfo?.companyRest) {
      values['weddingPlace'] = generalInfo?.companyRest?.solar ? 2 : 1;
    } else if (!createAction && !generalInfo?.companyRest?.both) {
      values['weddingPlace'] = generalInfo?.companyRest?.lago ? 2 : 1;
    }

    this.setState({
      showUnavailableModal: true,
      valuesUnavailableModal: values
    });
  }

  submitUnavailable = (values) => {
    if (values?.createAction) {
      this.createUnavailable(values);
    } else {
      confirm({
        title: 'Têm a certeza que pretende marcar o local como aberto?',
        content: (<div>
          <p>A data passará ao estado <b>"Fechado para manutenção"</b>.</p>
          <p>Apenas permitirá agendar atendimentos.</p>
        </div>
        ),
        okText: 'Marcar local aberto',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => {
          this.removeUnavailable(values);
        },
        onCancel: () => {
          this.setState({
            showUnavailableModal: false,
            valuesUnavailableModal: null
          });
        },
      });
    }
  }

  createUnavailable = async (values) => {
    const { history } = this.props;

    this.setState({ savingUnavailableModal: true });
    const unavailable = {
      startDate: moment.utc(values.date).startOf('day').toISOString(),
      endDate: moment.utc(values.date)?.endOf('day').toISOString(),
      allDay: true,
      total: true,
      startTime: null,
      endTime: null,
      companyRest: true,
      total: true,
      exceptionDayForCouplePayment: false,
      employee: null,
      user: getUserId(),
      weddingPlace: values?.weddingPlace
    };

    const result = await CreateTotalUnavailable(unavailable);
    // console.warn('Res', result);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Registada indisponibilidade c/local fechado!'
      });
      this.setState({
        savingUnavailableModal: false,
        showUnavailableModal: false,
        valuesUnavailableModal: null
      }, () => this.updateDataTable());
    } else {
      if (result?.data == 'UNAVAILABLE_IN_DATE_RANG') {
        setTimeout(() => {
          history.push('/rest-days');
        }, 700);
      }
      this.setState({
        savingUnavailableModal: false,
        showUnavailableModal: false,
        valuesUnavailableModal: null
      });
    }
  }

  removeUnavailable = async (value) => {
    const { history } = this.props;

    this.setState({ savingUnavailableModal: true });

    const result = await RemoveTotalUnavailableByAgenda(value.date, value.weddingPlace);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Registada indisponibilidade c/local aberto!'
      });
      this.setState({
        savingUnavailableModal: false,
        showUnavailableModal: false,
        valuesUnavailableModal: null
      }, () => this.updateDataTable());
    } else {
      if (result?.data == 'UNAVAILABLE_IN_DATE_RANG') {
        setTimeout(() => {
          history.push('/rest-days');
        }, 700);
      }
      this.setState({
        savingUnavailableModal: false,
        showUnavailableModal: false,
        valuesUnavailableModal: null
      });
    }
  }

  // Create menu
  onSelectCreateMenu = (value) => {
    const itemSelected = parseInt(value.key);

    // Create schedule
    if (itemSelected === 1) {
      this.setState({ showMeetingModal: true });
    }
    // Create internal meeting
    else if (itemSelected === 2) {
      this.setState({ showInternalMeetingModal: true });
    }
  }
  createMenu = (
    <Menu onClick={this.onSelectCreateMenu}>
      <Menu.Item key={1}>
        <Icon type="schedule" />
        Reunião Organização
      </Menu.Item>
      <Menu.Item key={2}>
        <Icon type="calendar" />
        Reunião Interna
      </Menu.Item>
    </Menu>
  );

  // Export Methods
  exportSchedules = async () => {
    const { filters, organizerMeetings } = this.state;
    this.setState({ download: true });
    let result;

    // Export all reserves information
    const filtersEnd = { ...filters, organizerMeetings };
    result = await DownloadSchedules(JSON.stringify(filtersEnd));

    if (result) {
      const fileTypeName = organizerMeetings
        ? 'Reunioes'
        : 'Visitas';
      let filename = `${fileTypeName}_${moment().format('YYYYMMDD')}.xlsx`;

      if (result?.headers) {
        const contentDisposition = result.headers.get('content-disposition');
        filename = contentDisposition.split('filename="')[1].split('"')[0];
      }

      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([result])); //result.blob
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      //Append to html link element page
      document.body.appendChild(link);

      //Start download
      link.click();

      //Clean up and remove the link
      link.parentNode.removeChild(link);
    }

    this.setState({ download: false });
  };

  fakePagination = (hasPagination) => {
    const { currentPage, pageSize } = this.state;

    return (<React.Fragment>
      {!hasPagination && <FakePagination className='fake-pagination mini'>
        <li className="fake-pagination-total-text">{`${((currentPage - 1) * pageSize) + 1}-${currentPage * pageSize} itens`}</li>
        <li title="Página Anterior"
          className={`fake-pagination-prev ${currentPage <= 1 ? 'fake-pagination-disabled' : ''}`}
          aria-disabled={currentPage <= 1 ? true : false}>
          <a className="fake-pagination-item-link"
            onClick={(value) => currentPage <= 1 ? null : this.handleChangePage(currentPage - 1)}>
            <Icon type="left" />
          </a>
        </li>
        <li title="Página Seguinte" className="fake-pagination-next">
          <a className="fake-pagination-item-link" onClick={(value) => this.handleChangePage(currentPage + 1)}>
            <Icon type="right" />
          </a>
        </li>
        <ChangeSizerInput
          pageSizeOptions={['15', '30', '45', '60']}
          input={{
            value: pageSize,
            onChange: value => this.handleChangeRowsPerPage(pageSize, value)
          }} />
      </FakePagination>}
    </React.Fragment>
    )
  }

  render() {
    const { history, location } = this.props;
    const { columns, columnsAttending, filters } = this.state;
    const { rows, total, loading, currentPage, pageSize, colors, expandedRows } = this.state;
    const { download, upload } = this.state;
    const { loadingEmployee, employee } = this.state;
    const { showMeetingModal, showVisitModal, valuesModal, savingModal } = this.state;
    const { showInternalMeetingModal, savingInternalMeetingModal } = this.state;
    const { showUnavailableModal, valuesUnavailableModal, savingUnavailableModal } = this.state;
    const { showNotesModal, notesModal, savingNotes } = this.state;
    const { organizerMeetings } = this.state;

    const title = location?.pathname == '/meetings' ? 'Reuniões' : 'Visitas';
    const subtitle = location?.pathname == '/meetings' ? 'Lista de reuniões' : 'Lista de visitas';

    const countBtns = isCurrentUserAdmin() ? 2 : 2;
    const hasPagination = filters?.oldSchedules
      || filters?.search
      || filters?.date
      || filters?.dates?.length > 0
      || filters?.year
      || filters?.status
      || filters?.weddingPlace
      || filters?.type
      || filters?.attendedBys?.length > 0
      ? true
      : false;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={countBtns}>
            <PageTitle>{title}</PageTitle>
            {subtitle}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={countBtns}>
            {isCurrentUserAdmin() && organizerMeetings
              && <Dropdown disabled={loading} overlay={this.createMenu} trigger={['click']}>
                <Button type='primary'>
                  <Icon type="plus" />
                  Criar
                  <Icon type="down" />
                </Button>
              </Dropdown>}
            {isCurrentUserAdmin() && !organizerMeetings && <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar visita'}
              disabled={loading}
              onClick={() => this.setState({ showVisitModal: true })}
            />}
            {isCurrentUserAdmin() && <BaseButton
              type={'default'}
              icon={'file-protect'}
              text={'Exportar'}
              loading={upload || download}
              disabled={loading}
              onClick={this.exportSchedules}
            />}
            {isCurrentUserEmployeeAttending() &&
              <BaseButton
                type="primary"
                icon="calendar"
                disabled={loadingEmployee}
                text={loadingEmployee ? 'A carregar...' : employee?.availableRegisters ? 'Dias Disponíveis' : 'Dias Indisponíveis'}
                onClick={() => { history.push('/rest-days') }}
              />
            }
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={countBtns}>
          {isCurrentUserEmployeeAttending()
            ? <AttendingSchedulesFilters
              loading={loading}
              queryChange={this.handleFilterChange} />
            : <SchedulesFilters
              loading={loading}
              organizerMeetings={organizerMeetings}
              location={this.props.location}
              queryChange={this.handleFilterChange} />}

          {this.fakePagination(hasPagination)}

          <TableExpanded
            columns={isCurrentUserEmployeeAttending() ? columnsAttending : columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            // scroll={{ x: 1700 }}
            emptyIcon="calendar"
            emptyText={location?.pathname == '/meetings' ? 'Não existem reuniões' : 'Não existem visitas'}
            total={total}
            rowKey={data => data?.id}
            hasPagination={hasPagination}
            hasTopPagination={hasPagination}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            defaultExpandAllRows={true}
            defaultExpandedRowKeys={rows.filter(f => f?.schedules?.length > 0)?.map(m => m?.id) || []}
            expandedRowKeys={rows.filter(f => f?.schedules?.length > 0)?.map(m => m?.id) || []}
            expandedRowRender={(data) => {
              return organizerMeetings
                ? <MeetingsPageDetail
                  loading={loading}
                  colors={colors}
                  rows={data.schedules}
                  history={this.props.history}
                  onChange={() => this.updateDataTable()}
                  handleOpenChildModal={this.handleOpenChildModal} />
                : <VisitsPageDetail
                  loading={loading}
                  colors={colors}
                  rows={data.schedules}
                  history={this.props.history}
                  onChange={() => this.updateDataTable()}
                  handleOpenChildModal={this.handleOpenChildModal} />
            }}
            onExpandedRowsChange={(val) => this.setState({ expandedRows: val })}
          />

          {this.fakePagination(hasPagination)}

          {showMeetingModal
            && <MeetingModal
              isOpen={showMeetingModal}
              saving={savingModal}
              initialValues={valuesModal}
              onClose={() => this.setState({ showMeetingModal: false, valuesModal: null })}
              onSubmit={(values) => this.submitSchedule(values)}
            />}

          {showVisitModal
            && <VisitModal
              isOpen={showVisitModal}
              saving={savingModal}
              initialValues={valuesModal}
              onClose={() => this.setState({ showVisitModal: false, valuesModal: null })}
              onSubmit={(values) => this.submitSchedule(values)}
            />}

          {showInternalMeetingModal
            && <ScheduleInternalModal
              isOpen={showInternalMeetingModal}
              saving={savingInternalMeetingModal}
              initialValues={{}}
              onClose={() => this.setState({ showInternalMeetingModal: false })}
              onSubmit={(values) => this.submitInternalSchedule(values)}
            />}

          {showUnavailableModal
            && <UnavailableModal
              isOpen={showUnavailableModal}
              saving={savingUnavailableModal}
              initialValues={valuesUnavailableModal}
              onClose={() => this.setState({ showUnavailableModal: false, valuesUnavailableModal: null })}
              onSubmit={(values) => this.submitUnavailable(values)}
            />}

          {showNotesModal
            && <ScheduleNotesModal
              isOpen={showNotesModal}
              saving={savingNotes}
              onlyNotes={true}
              initialValues={notesModal}
              onSubmit={(values) => this.onNotesModalSubmit(values)}
              onClose={() => this.setState({ showNotesModal: false, notesModal: null })} />}
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(SchedulesPage);
