import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Popconfirm, Icon } from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  DefaultSectionOption,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import AgendaIcon from '../../assets/icons/ic_agenda.svg';
import ArchIcon from '../../assets/icons/ic_arch.svg';
import BlueprintIcon from '../../assets/icons/ic_blueprint.svg';
import BuffetIcon from '../../assets/icons/ic_buffet.svg';
import CateringIcon from '../../assets/icons/ic_catering.svg';
import CellarIcon from '../../assets/icons/ic_cellar.svg';
import ChurchIcon from '../../assets/icons/ic_church.svg';
import DestinationIcon from '../../assets/icons/ic_destination.svg';
import GiftIcon from '../../assets/icons/ic_gift.svg';
import ReportIcon from '../../assets/icons/ic_report.svg';
import LetterIcon from '../../assets/icons/ic_letter.svg';
import ContentCategoryModal from './ContentCategoryModal';
import BaseButton from '../../components/buttons/BaseButton';
import { CreateContentCategory, DeleteContentCategory, GetContentCategories, UpdateContentCategory } from '../../infra/requests/ContentCategoryRequests';
import { FloatButton, OptionBox } from '../food/FoodStyles';
import Alerts from '../../components/alert/Alert';

const ChooseIcon = id => {
  if (id === '5c822fe5bd1ff815330bb492') return ArchIcon;
  if (id === '5c823001bd1ff815330bb494') return CellarIcon;
  if (id === '5dc0054f29f787cb8a1249ff') return BuffetIcon;
  if (id === '5dc0056129f787cb8a124a1f') return GiftIcon;
  if (id === '5c822fc9bd1ff815330bb48f') return CateringIcon;
  if (id === '5c822f0fbd1ff815330bb48c') return LetterIcon;
  if (id === '5c822ff1bd1ff815330bb493') return ChurchIcon;
  if (id === '5c822fa4bd1ff815330bb48d') return AgendaIcon;
  if (id === '5c822fb7bd1ff815330bb48e') return DestinationIcon;
  if (id === '5dc0056d29f787cb8a124a32') return ReportIcon;
  if (id === '5c822fdcbd1ff815330bb491') return BlueprintIcon;
  return ArchIcon;
};

const RemoveMessage = (
  <div>
      <div>Tem a certeza que quer remover esta categoria? </div>
      <div>
          Toda a informação da categoria será removida da plataforma e não
          conseguirá ser recuperada.
      </div>
  </div>
);

class InformationPages extends Component {
  state = {
    loading: false,
    categories: [],

    loadingModal: false,
    showModal: false,
    dataModal: null
  };

  componentDidMount = async () => {
    this.getCategories();
  }

  getCategories = async () => {
    this.setState({ loading: true });
    const result = await GetContentCategories();

    if (result.success) {
      this.setState({ categories: result?.data ? result.data : [] });
    }
    this.setState({ loading: false });
  }

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/information/${record._id}`);
  };

  saveContentCategory = async (values) => {
    // console.warn('values', values);
    if (values?.tag && /\s/.test(values?.tag)) {
      Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'A tag de tradução não pode conter espaços!'
      });
      return;
    }

    this.setState({ loadingModal: true});

    const result = values?._id
      ? await UpdateContentCategory(values._id, values)
      : await CreateContentCategory(values);

    this.setState({ loadingModal: false, showModal: false, dataModal: false }, () => {
      if (result.success) this.getCategories();
    });
  }

  deletContentCategory = async (id) => {
    const result = await DeleteContentCategory(id);

    if(result.success) this.getCategories();
  }

  render() {
    const { history } = this.props;
    const { categories } = this.state;
    const { loadingModal, showModal, dataModal } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Informações</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type="primary"
              icon="plus"
              text="Adicionar Categoria"
              onClick={() => this.setState({ showModal: true, dataModal: {}})}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <Row gutter={[24, 24]}>
            {categories.map(category => (
              <Col key={category._id} xs={6}>
                <OptionBox>
                  <DefaultSectionOption
                    onClick={() => history.push(`/information/${category._id}`)}>
                    {/* <img src={ChooseIcon(category._id)} alt="icon" /> */}
                    <img src={category.icon} alt="icon" />
                    <span>{category.name}</span>
                  </DefaultSectionOption>
                  {
                    category?.canBeDeleted &&
                    <FloatButton number={0}>
                      <Popconfirm
                        placement="topRight"
                        title={RemoveMessage}
                        onConfirm={() => this.deletContentCategory(category._id)}>
                        <Icon type="delete" />
                      </Popconfirm>
                    </FloatButton>
                  }
                  <FloatButton
                    number={!category?.canBeDeleted ? 0 : 1}
                    onClick={() => this.setState({ showModal: true, dataModal: category })}>
                    <Icon type="edit" />
                  </FloatButton>
                </OptionBox>
              </Col>
            ))}
          </Row>

          {showModal &&
            <ContentCategoryModal
              loading={loadingModal}
              isOpen={showModal}
              initialValues={dataModal}
              onSubmit={this.saveContentCategory}
              onClose={() => this.setState({ showModal: false, dataModal: null })}
              onDelete={this.deletContentCategory} />
          }

        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.info.content_categories
});

export default connect(mapStateToProps)(InformationPages);
