import styled from 'styled-components';
import { Collapse } from 'antd';
import { size } from '../../styles/Responsive';
const { Panel } = Collapse;

export const RegisterBox = styled.div`
  display: block;
  width: 100%;
  max-width: 600px;
  padding: 15px;
  background: #faf9f9;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
`;

export const RegisterBoxPayment = styled.div`
  display: block;
  width: 100%;
  max-width: 600px;
  padding: 15px;
  background: ${({ phaseConcluded, mainColor }) => phaseConcluded ? mainColor : '#faf9f9'}
  margin-bottom: 20px;
  text-align: left;
  position: relative;
`;

export const Title = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: bold;
`;

export const Description = styled.div`
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
`;
export const Value = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
`;
export const Date = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
`;

export const OptionButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  top: -5px;
  right: ${({ number }) => -5 + number * 30}px;
  box-shadow: 1px 1px 3px #ccc;
`;

export const DetailedLine = styled.div`
  display: inline-block;
  width: 100%;
  padding: 5px 0;
  text-align: left;
  font-weight: bold;
`;

export const CollapseContainer = styled(Collapse)`
  &&& {
    background-color: #ffffff;
    border: 0;

    & .ant-collapse-item {
      border-bottom: 1px solid #cacaca;
    }

    & .ant-collapse-header {
      padding: 0px;
    }

    & .ant-collapse-content {
      border-top: 0;

      & .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
`;

export const PanelContainer = styled(Panel)`

`;

export const NoRecords = styled.div`
  font-size: 13px;
  padding: 10px 0px;
`;

export const BudgetGuestsNote = styled.div`
  padding: 5px;
  color: #000000;
  background-color: #E0E0E0;
  margin-bottom: 10px;
  text-align: left;
`;

export const BudgetContainer = styled.div`
  width: 100%;
  margin-top: ${({ subpage, printed }) => printed ? 125 : subpage ? 0 : 80}px;
  display: inline-block;

  @media (max-width:${size.tablet}) {
    margin-top: ${({ subpage, printed }) => printed ? 125 : subpage ? 0 : 100}px;
  }
`;

export const PageContainer = styled.div`
  width: ${({ notesOpen }) => notesOpen ? 'calc(100% - 300px)' : '100%'};
  height: ${({ printed }) => printed ? 'calc(100vh - 195px)' : 'calc(100vh - 150px)'};
  overflow-y: auto;
  position: relative;
  display: inline-block;
  padding: 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

export const BudgetInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 20px;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #000000;

  ul {
    list-style-type: none;
    margin-block: 0;
    padding-inline-start: 0;
    width: 100%;
  }

  ul li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 30px;
  }

  ul li.line {
    border-bottom: 1px solid #cacaca;
  }

  ul li.iban, 
  ul li.updated, 
  ul li.payment-phase .title,
  ul li.payment-phase .price {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  ul li.payment-phase .title {
    line-height: 15px;
  }

   ul li.payment-phase .phase-info {
    flex: 1;
  }

  ul li.iban, 
  ul li.payment-phase {
    margin-bottom: 10px;
  }

  ul li.payment-phase .price .preview {
    margin-right: 30px;
    white-space: nowrap;
  }

  ul li.payment-phase .title b {
    color: ${p => p?.theme?.primaryColor};
  }
  
  ul li span,
  ul li.payment-phase .subtitle {
    text-align: initial;
    font-size: 13px;
  }

  ul li.payment-phase .subtitle .anticon {
    margin-right: 5px;
  }

  ul li.iban span, 
  ul li.updated span, 
  ul li.payment-phase .title span {
    padding-left: 5px;
  }

  ul li.payment-phase .price span span {
    padding: 0 5px;
  }
`;

export const PaymentPhaseWithEmailContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const PaymentPhaseWithEmailButton = styled.div`
  cursor: pointer;
  padding: 0px 10px 0px 10px;
  box-shadow: 1px 1px 3px #ccc;
  font-size: 20px;
  margin-bottom: 5px;
  background: ${({ alreadyNotified, mainColor }) => alreadyNotified ? '#faf9f9' : mainColor}
`;

export const BudgetPercentage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  .input-percentage {
    display: flex;
  }

  .input-number {
    max-width: 100px;
    margin-right: 5px;
  }

  @media max-width: ${size.laptop} {
    width: 100%;
  }

  @media (min-width: 901px) {
    width: fit-content;

    label {
      margin-right: 5px;
    }
  }
`;