import React, { Component, Fragment } from 'react';
import { Icon, Popconfirm, Row, Col, Modal } from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import {
  GetBudget,
  UpdateBudget,
  CreateBudget,
  RemoveBudget,
  getWeddingInfo,
  NotifyConfirmedPayment,
  NotifyCoupleAboutCompletedPaymentPhase
} from '../../infra/requests/WeddingProcessRequests';
import { GetUpgrade } from '../../infra/requests/UpgradesRequests';
import { BaseButton } from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  SpinLoading,
  HeaderButtonsContainer,
  SectionTitle
} from '../../styles/BasicStyles';
import { SaveNotes, AddWedding, ChangeAboutUs } from '../../redux/wedding/wedding.actions';
import { SaveSectionNotes } from '../../infra/requests/WeddingProcessRequests';
import BudgetModal from './BudgetModal';
import {
  RegisterBox,
  Description,
  Title,
  Value,
  Date,
  OptionButton,
  DetailedLine,
  CollapseContainer,
  PanelContainer,
  NoRecords,
  BudgetGuestsNote,
  BudgetContainer,
  PageContainer,
  BudgetInfo,
  BudgetPercentage,
  PaymentPhaseWithEmailContainer,
  PaymentPhaseWithEmailButton,
  RegisterBoxPayment
} from './BudgetStyles';
import BudgetGraph from './components/BudgetGraph';
import { numberFormat } from '../../infra/services/calc/numbers';
//import CommentsDrawer from '../../components/comments/CommentsDrawer';
import DetailBlock from './components/DetailBlock';
import BaseServiceBlock from './components/BaseServiceBlock';
import BillingBlock from './components/BillingBlock';
import NotesConfirmModal from '../../components/notesModal/NotesModal';
import { hasNotes } from '../../infra/services/notes/FindNotes';
import PrintedBar from '../dashboard/components/PrintedBar';
import CommentsDrawerFixed from '../../components/comments/CommentsDrawerFixed';
import { getBudgetPrintedConfirmation, getPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { GetQuestionForBudget } from '../../infra/requests/QuestionsRequests';
import { GetConfigByWedding } from '../../infra/requests/ConfigsRequests';
import Alerts from '../../components/alert/Alert';
import { UpdateBudgetInfo, ValidateWeddingBudget } from '../../infra/requests/WeddingRequests';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import NumberInput from '../../components/inputs/NumberInput';
import { inRoomPlanTest } from '../../infra/helpers/Helpers';
import { checkPreviousPhasesCompleted, getMissingValue, getPhaseDate } from './budgetUtils';
import { CostTypes } from '../../infra/services/options/Options';
import { VenueColors } from '../../infra/services/wedding/weddingUtils';
import { GetVenueColors } from '../../infra/requests/ColorSystemRequests';
import { GraphColors } from '../../styles/Colours';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { DocumentSection } from '../about/components/Styles';
import FileOption from '../ChoicesPage/components/FileOption';
import { DivMarginTop } from '../../weddingday/orders/components/Styles';

const { confirm } = Modal;

const graphData1 = [
  {
    name: 'Pago',
    value: 0,
    color: GraphColors.paid
  },
  {
    name: 'Falta',
    value: 0,
    color: GraphColors.missing
  }
];

const graphData2 = [
  {
    name: 'Serviço Base',
    value: 0,
    color: GraphColors.base,
    tag: 'BUDGET_ADJUDICATED'
  },
  {
    name: 'Extras Plano de Sala',
    value: 0,
    color: GraphColors.roomPlan,
    tag: 'BUDGET_ROOM_PLAN'
  },
  {
    name: 'Extras Cerimónia',
    value: 0,
    color: GraphColors.ceremony,
    tag: 'BUDGET_CEREMONY'
  },
  {
    name: 'Extras Staff',
    value: 0,
    color: GraphColors.staff,
    tag: 'BUDGET_STAFF'
  },
  {
    name: 'Extras Food Selection',
    value: 0,
    color: GraphColors.food,
    tag: 'BUDGET_FOOD'
  },
  {
    name: 'Extras Drink Selection',
    value: 0,
    color: GraphColors.drink,
    tag: 'BUDGET_DRINK'
  },
  {
    name: 'Extras de Decoração',
    value: 0,
    color: GraphColors.decor,
    tag: 'BUDGET_DECORATION'
  },
  {
    name: 'Extras Party Selection',
    value: 0,
    color: GraphColors.party,
    tag: 'BUDGET_PARTY'
  },
  {
    name: 'Upgrade',
    value: 0,
    color: GraphColors.upgrade,
    tag: 'BUDGET_UPGRADE'
  },
  {
    name: 'Valores a adicionar (+)',
    value: 0,
    color: GraphColors.expense,
    tag: 'BUDGET_EXPENSES'
  }
];

const budgetExpenses = {
  name: 'Valores a adicionar (+)',
  value: 0,
  tag: 'BUDGET_EXPENSES',
  index: 0,
};

const budgetOffers = {
  name: 'Ofertas (-)',
  value: 0,
  tag: 'BUDGET_OFFERS',
  index: 2,
};

const budgetDiscounts = {
  name: 'Valores a descontar (-)',
  value: 0,
  tag: 'BUDGET_DISCOUNTS',
  index: 1,
};

class BudgetPage extends Component {
  state = {
    ready: false,
    drawer: false,
    loading: true,
    openModal: false,
    openNotesConfirm: false,
    edit: false,
    graph1: graphData1,
    graph2: graphData2,
    total: 0,
    budget: [],
    extras: [],
    numGuests: 0,
    numTables: 0,
    minGuests: 0,
    numPlaces: 0,
    numChildren: 0,
    numStaff: 0,
    numPax: 0,
    numPaxShow: 0,
    music: 0,
    priceGuest: 0,
    upgrade: undefined,
    questionsPaymentMethods: [],
    paymentPhases: [],
    venueConfig: null,
    validatingBudget: false,
    updatingPercentage: false,
    percentageCalc: 40,
    percentageCalc4: 80,
    colors: VenueColors,
    localColor: null,
    currentPaymentProof: {}
  };

  componentDidMount() {
    this.getInformation();
    this.getLocalColors();
  }

  calculateSections = (data = {}) => {
    let total = 0;
    const graph2 = graphData2.map(section => {
      if (section.name === 'Serviço Base')
        section.value = data?.costs?.base || 0;
      if (section.name === 'Extras Plano de Sala')
        section.value = data?.costs?.tables || 0;
      if (section.name === 'Extras Cerimónia')
        section.value = data?.costs?.ceremony || 0;
      if (section.name === 'Extras Staff')
        section.value = data?.costs?.staff || 0;
      if (section.name === 'Extras Food Selection')
        section.value = data?.costs?.food || 0;
      if (section.name === 'Extras Drink Selection')
        section.value = data?.costs?.drinks || 0;
      if (section.name === 'Extras de Decoração')
        section.value = data?.costs?.decoration || 0;
      if (section.name === 'Extras Party Selection')
        section.value = data?.costs?.party || 0;
      if (section.name === 'Upgrade')
        section.value = data?.costs?.upgrade || 0;
      if (section.name === 'Valores a adicionar (+)')
        section.value = data?.costs?.expense || 0;
      total = total + section.value;
      return section;
    });

    //Expenses
    const expenses = data?.costs?.expense || 0;
    budgetExpenses.value = expenses;

    //Offers
    const offers = data?.costs?.offer || 0;
    budgetOffers.value = offers;

    //Discounts
    const discounts = data?.costs?.discount || 0;
    budgetDiscounts.value = discounts;

    total = total - offers - discounts;

    let payed = 0;
    if (data.budget) {
      data.budget.forEach(line => {
        if (line.type === 'PAYMENT') payed = payed + line.value;
      });
    }
    const missing = total - payed;
    const graph1 = [
      {
        name: 'Pago',
        value: payed,
        color: GraphColors.paid
      },
      {
        name: 'Falta',
        value: missing,
        color: GraphColors.missing
      }
    ];

    return {
      total,
      missing,
      graph1,
      graph2,
      budget: data.budget || []
    };
  };

  getLocalColors = async () => {
    const { wedding } = this.props;

    const result = await GetVenueColors();
    const dataColors = result?.success ? result?.data : VenueColors;
    const colorByPlace = wedding?.wedding?.wedding_place == 1
      ? dataColors?.solar
      : wedding?.wedding?.wedding_place == 2
        ? dataColors?.lago
        : null;

    this.setState({
      colors: dataColors,
      localColor: colorByPlace
    })
  }

  getInformation = async () => {
    const {
      wedding,
      match: { params }
    } = this.props;
    const percentageCalc = wedding?.wedding?.percentageCalc || 40;
    const percentageCalc4 = wedding?.wedding?.percentageCalc4 || 80;
    let budget = undefined;
    let { data, success } = await GetBudget(wedding.wedding._id);
    if (success) budget = data;
    const questionsPaymentMethods = success && data?.questions
      ? data?.questions
      : [];
    const paymentPhases = success && data?.paymentPhases
      ? data?.paymentPhases
      : [];

    const resultVenueConfig = await GetConfigByWedding(wedding.wedding._id);
    const venueConfig = resultVenueConfig?.success && resultVenueConfig?.data
      ? resultVenueConfig?.data
      : null;

    let upgrade = undefined;
    if (wedding.wedding.upgrade) {
      ({ data, success } = await GetUpgrade(wedding.wedding.upgrade._id ? wedding.wedding.upgrade._id : wedding.wedding.upgrade));
      if (success) upgrade = data;
    }

    this.setState({
      loading: false,
      ready: true,
      ...this.calculateSections(budget),
      extras: budget.extras || [],
      numGuests: budget.guests || 0,
      numTables: budget.tables || 0,
      minGuests: budget.min_people || 0,
      numPlaces: budget.places || 0,
      numChildren: budget.children || 0,
      numStaff: budget.staff || 0,
      numPax: budget.pax || 0,
      numPaxShow: budget.pax_show || 0,
      music: budget.music || 0,
      priceGuest: budget.price || 0,
      upgrade,
      questionsPaymentMethods,
      paymentPhases,
      venueConfig,
      percentageCalc,
      percentageCalc4
    });
  };

  openModal = (data = { date: moment.utc() }) => {
    const { dispatch } = this.props;
    if (data?.document && !data?.document?.size) {
      data.document.size = data?.document?.length;
    }
    dispatch(initialize('manage_budget_form', { ...data }));
    this.setState({ openModal: true, edit: data._id, currentPaymentProof: data });
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(initialize('manage_budget_form', {}));
    this.setState({
      openModal: false,
      edit: false,
      currentPaymentProof: {}
    });
  };

  onSubmit = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getBudgetPrintedConfirmation(),
        onOk: () => { this.saveBudgetRegister(values); },
        onCancel: () => { this.closeModal(); },
      });
    } else {
      this.saveBudgetRegister(values);
    }
  };

  saveBudgetRegister = async values => {
    const {
      match: { params },
      wedding,
      dispatch,
      ChangeAboutUs,
    } = this.props;
    this.setState({ loading: true });
    let data = { ...values };
    if (!data?.description) data['description'] = ' ';
    if (!data?.value) data['value'] = 0;
    if (!data?.title) data['title'] = ' ';
    if (data?.document?._id) data['document'] = data?.document?._id;

    const phase = wedding.wedding.paymentPhases.find(f => f._id === values.phase);
    if (phase) values.phaseOrder = phase.order;

    let payload;
    if (values?.type === 'PAYMENT_PROOF') {
      payload = FlattenToFormData({ ...data, wedding: params.wedding });
      if (values?.document?.blob) {
        payload.append('file', values?.document?.blob);
        payload.delete('document');
      }
    } else {
      payload = { ...values, wedding: params.wedding };
    }

    const result = values._id
      ? await UpdateBudget(values._id, payload)
      : await CreateBudget(payload);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Registo guardado!'
      });
      dispatch(ChangeAboutUs(result?.data?.wedding));
    }

    this.closeModal();
    await this.getInformation();
  };

  notifyCoupleAboutPaymentApprove = async (id) => {
    await NotifyConfirmedPayment(id);
    await this.getInformation();
  };

  notifyCoupleAboutPhaseComplete = async (order) => {
    const { wedding } = this.props;    
    await NotifyCoupleAboutCompletedPaymentPhase(wedding?.wedding?._id, order);
    await this.getInformation();
  };

  isPhaseComplete = (order) => {
    const { paymentPhases } = this.state;
    const phase = paymentPhases.find(p => p?.order === order);
    if (!phase) {
      return false;
    }

    if (!phase?.payments || phase?.payments?.length === 0) {
      return false;
    }

    for (const payment of phase?.payments) {
      if (!payment?.phaseComplete) {
        return false;
      }
    }

    return true;
  }

  isPhaseAlreadyNotified = (order) => {
    const { paymentPhases } = this.state;
    const phase = paymentPhases.find(p => p?.order === order);
    if (!phase) {
      return false;
    }

    return phase?.notifiedComplete;
  }

  deleteBudget = async (id) => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getBudgetPrintedConfirmation(),
        onOk: () => { this.deleteBudgetRegister(id); },
        onCancel: () => { this.closeModal(); },
      });
    } else this.deleteBudgetRegister(id)
  };

  deleteBudgetRegister = async (id) => {
    await RemoveBudget(id);
    await this.getInformation();
  }

  openNotes = () => {
    const { wedding, dispatch } = this.props;
    const { drawer } = this.state;

    if (!drawer) {
      const notes = wedding.notes.find(note => note.section === 'BUDGET');
      dispatch(initialize('wedding_notes_section_form', notes));
      this.setState({ drawer: true });
    }
  };

  closeNotes = () => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'BUDGET');
    dispatch(initialize('wedding_notes_section_form', notes));
    this.setState({ drawer: false, openNotesConfirm: false });
  };

  checkBeforeCloseNotes = (values) => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'BUDGET');

    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (notes?.notes !== values.notes || notes?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      this.setState({ openNotesConfirm: true });
    }
    //Otherwise, just the close the notes
    else {
      this.setState({ drawer: false });
    }
  }

  saveNotes = async values => {
    const { wedding } = this.props;
    if (wedding?.wedding?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk: () => { this.updateNotes(values); },
        onCancel: () => { this.closeNotes(); },
      });
    } else this.updateNotes(values);
  };

  updateNotes = async (values) => {
    const { saveNotesOnReducer, wedding, dispatch } = this.props;
    this.setState({ loading: true });
    const { data } = await SaveSectionNotes(wedding.wedding._id, {
      ...values,
      section: 'BUDGET'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);

    const { openNotesConfirm } = this.state;
    if (openNotesConfirm) {
      return this.setState({
        loading: false,
        drawer: false,
        openNotesConfirm: false
      });
    }
    return this.setState({ loading: false });
  };

  renderPriceDetail = (type, price, quantity, cost) => {
    const { numPax, numGuests, numChildren, numTables } = this.state;

    if (type === CostTypes.QUANTITY) {
      return `${numberFormat(cost !== undefined ? cost : price)}€/un x ${quantity} unidades`;
    } else if (type === CostTypes.GUEST) {
      return `${numberFormat(cost !== undefined ? cost : price)}€/pax x ${numPax} pax`;
    } else if (type === CostTypes.PEOPLE) {
      return `${numberFormat(cost !== undefined ? cost : price)}€/pessoa x ${numGuests + numChildren} pessoas`;
    } else if (type === CostTypes.TABLE) {
      return `${numberFormat(cost !== undefined ? cost : price)}€/mesa x ${numTables} mesas`;
    }

    //Global
    return 'Valor';
  };

  renderPrice = (type, price, quantity, cost) => {
    const { numPax, numGuests, numChildren, numTables } = this.state;
    if (cost !== undefined) cost = parseFloat(cost);

    if (type === CostTypes.QUANTITY) {
      return `${numberFormat(quantity * ((cost !== undefined ? cost : price)))}€`;
    } else if (type === CostTypes.GUEST) {
      return `${numberFormat(numPax * ((cost !== undefined ? cost : price)))}€`;
    } else if (type === CostTypes.PEOPLE) {
      return `${numberFormat((numGuests + numChildren) * (cost !== undefined ? cost : price))}€`;
    } else if (type === CostTypes.TABLE) {
      return `${numberFormat(numTables * ((cost !== undefined ? cost : price)))}€`;
    }

    //Global
    return `${numberFormat((cost !== undefined ? cost : price))}€`;
  };

  renderCollapseHeader = section => {
    return (
      <DetailedLine>
        <Row>
          <Col xs={12}>{section.name}</Col>
          <Col xs={12}>
            {section.value > 0 ? `${numberFormat(section.value)}€` : ''}
          </Col>
        </Row>
      </DetailedLine>
    );
  };

  renderDetailedExpenses = tag => {
    const {
      extras,
      minGuests,
      numGuests,
      numChildren,
      numStaff,
      numPaxShow,
      numPlaces,
      priceGuest,
      music,
      upgrade,
      budget,
      localColor
    } = this.state;

    switch (tag) {
      case 'BUDGET_ADJUDICATED':
        return (
          <React.Fragment>
            <BudgetGuestsNote>
              Quanto ao orçamento base, ter em consideração que, é considerado o número mínimo de convidados para a vossa data, sempre que número de convidados previsto ou definitivo seja inferior.
            </BudgetGuestsNote>
            <BaseServiceBlock
              minGuests={minGuests}
              price={`${numberFormat(priceGuest)}€`}
              pax={numPaxShow}
              seats={numPlaces}
            />
            {numGuests > 0 && (
              <DetailBlock
                title="Convidados com + de 8 anos"
                subtitle={`${numGuests} x ${numberFormat(priceGuest)}€`}
                subtitleValue={`${numberFormat(numGuests * priceGuest)}€`}
              />
            )}
            {numChildren > 0 && (
              <DetailBlock
                title="Crianças (3 a 7 anos)"
                subtitle={`${numChildren} x ${numberFormat(priceGuest / 2)}€`}
                subtitleValue={`${numberFormat(
                  numChildren * (priceGuest / 2)
                )}€`}
              />
            )}
            {numStaff > 0 && (
              <DetailBlock
                title="Staff dos noivos"
                subtitle={`${numStaff} x ${numberFormat(priceGuest / 2)}€`}
                subtitleValue={`${numberFormat(numStaff * (priceGuest / 2))}€`}
              />
            )}
            {music > 0 && (
              <DetailBlock
                title="Licença de Música (mecânica)"
                subtitle={`${numberFormat(music)}€`}
              />
            )}
          </React.Fragment>
        );
      case 'BUDGET_ROOM_PLAN':
        return inRoomPlanTest() && extras?.tables?.qtd > 0 && extras?.tables?.cost > 0
          ? <DetailBlock
            title="Mesas extra (mesas com quantidade de lugares abaixo do número mínimo)"
            subtitle={`${extras?.tables?.qtd} x ${numberFormat(extras?.tables?.cost || 0)}€`}
            subtitleValue={`${numberFormat(extras?.tables?.qtd * (extras?.tables?.cost || 0))}€`}
          />
          : <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_CEREMONY':
        if (extras && extras.ceremony?.length > 0) {
          return extras.ceremony.map((expense, index) => (
            <DetailBlock
              key={`ceremony_${index}`}
              title={expense.title.pt}
              subtitle={
                this.renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                this.renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_STAFF':
        if (extras && extras.staff?.length > 0) {
          return extras.staff.map((expense, index) => (
            <DetailBlock
              key={`staff_${index}`}
              title={expense.title.pt}
              subtitle={
                this.renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                this.renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_FOOD':
        if (extras && extras.food?.length > 0) {
          return extras.food.map((expense, index) => {
            const isSidedishe = expense.category && expense.category === 'sidedishes' ? true : false;
            let title = expense.title ? expense.title.pt : expense.name ? expense.name.pt : '';
            if (isSidedishe) title = `${title} - Guarnição`;
            return <DetailBlock
              key={`food_${index}`}
              title={title}
              subtitle={
                this.renderPriceDetail(
                  expense.name ? CostTypes.GUEST : expense.cost_type,
                  expense.name ? expense.extra_cost : expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                this.renderPrice(
                  expense.name ? CostTypes.GUEST : expense.cost_type,
                  expense.name ? expense.extra_cost : expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          });
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_DRINK':
        if (extras && extras.drinks?.length > 0) {
          return extras.drinks.map((expense, index) => (
            <DetailBlock
              key={`drink_${index}`}
              title={expense.title.pt}
              subtitle={
                this.renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                this.renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_DECORATION':
        if (extras && extras.decoration?.length > 0) {
          return extras.decoration.map((expense, index) => (
            <DetailBlock
              key={`decoration_${index}`}
              title={expense.title.pt}
              subtitle={
                this.renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                this.renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_PARTY':
        if (extras && extras.party?.length > 0) {
          return extras.party.map((expense, index) => (
            <DetailBlock
              key={`party_${index}`}
              title={expense.title.pt}
              subtitle={
                this.renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                this.renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              distinguish={expense.distinguishBudget}
              color={localColor}
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_UPGRADE':
        if (upgrade) {
          return (
            <DetailBlock
              title={upgrade.name.pt}
              subtitle={
                upgrade.price > 0
                  ? this.renderPriceDetail(CostTypes.GUEST, upgrade.price, 0, undefined)
                  : null
              }
              subtitleValue={
                upgrade.price > 0
                  ? this.renderPrice(CostTypes.GUEST, upgrade.price, 0, undefined)
                  : null
              }
            />
          );
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_EXPENSES': //'BUDGET_OTHERS':
        const expenses = budget.filter(b => b?.type === 'EXPENSE');

        if (expenses.length > 0) {
          return expenses.map((expense, index) => (
            <DetailBlock
              key={`others_${index}`}
              title={expense.title}
              subtitle={
                expense.value > 0
                  ? this.renderPriceDetail(CostTypes.GLOBAL, expense.value, 0, undefined)
                  : null
              }
              subtitleValue={
                expense.value > 0
                  ? this.renderPrice(CostTypes.GLOBAL, expense.value, 0, undefined)
                  : null
              }
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_OFFERS':
        const offers = budget.filter(b => b?.type === 'OFFER');

        if (offers.length > 0) {
          return offers.map((offer, index) => (
            <DetailBlock
              key={`offers_${index}`}
              title={offer.title}
              subtitle={
                offer.value > 0
                  ? this.renderPriceDetail(CostTypes.GLOBAL, offer.value, 0, undefined)
                  : null
              }
              subtitleValue={
                offer.value > 0
                  ? this.renderPrice(CostTypes.GLOBAL, offer.value, 0, undefined)
                  : null
              }
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      case 'BUDGET_DISCOUNTS':
        const discounts = budget.filter(b => b?.type === 'DISCOUNT');

        if (discounts.length > 0) {
          return discounts.map((discount, index) => (
            <DetailBlock
              key={`discounts_${index}`}
              title={discount.title}
              subtitle={
                discount.value > 0
                  ? this.renderPriceDetail(CostTypes.GLOBAL, discount.value, 0, undefined)
                  : null
              }
              subtitleValue={
                discount.value > 0
                  ? this.renderPrice(CostTypes.GLOBAL, discount.value, 0, undefined)
                  : null
              }
            />
          ));
        }
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
      default:
        return <NoRecords>Não existem registos a apresentar.</NoRecords>;
    }
  };

  validateBudget = async () => {
    const { wedding, AddWedding, dispatch } = this.props;
    this.setState({ validatingBudget: true });

    const data = {
      validate: true,
      date: moment().utc(true).toISOString(),
      percentage: wedding.wedding?.percentageCalc || 40,
      percentage4: wedding.wedding?.percentageCalc4 || 80
    };
    const result = await UpdateBudgetInfo(wedding.wedding._id, data);

    if (result?.success) {
      const { data, success } = await getWeddingInfo(wedding.wedding._id);
      if (success && data) AddWedding(data);

      this.setState({
        validatingBudget: false
      });

      return Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Orçamento validado!'
      })
    } else {
      this.setState({ validatingBudget: false });
      return Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao validar o orçamento. Tente novamente.'
      });
    }
  }

  updateBudgetPercentage = async () => {
    const { wedding, AddWedding, dispatch } = this.props;
    const { percentageCalc, percentageCalc4 } = this.state;
    this.setState({ updatePercentage: true });

    const data = {
      validate: false,
      date: moment().utc(true).toISOString(),
      percentage: percentageCalc,
      percentage4: percentageCalc4
    };
    const result = await UpdateBudgetInfo(wedding.wedding._id, data);

    if (result?.success) {
      const { data, success } = await getWeddingInfo(wedding.wedding._id);
      if (success && data) AddWedding(data);

      this.setState({
        validatingBudget: false,
        percentageCalc: data?.wedding?.percentageCalc,
        percentageCalc4: data?.wedding?.percentageCalc4,
      });

      return Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Percentagem de cálculo atualizada!'
      })
    } else {
      this.setState({ validatingBudget: false, percentageCalc: wedding.wedding?.percentageCalc || 40, percentageCalc4: wedding.wedding?.percentageCalc4 || 80 });
      return Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao atualizar a percentagem de cálculo. Tente novamente.'
      });
    }
  }

  checkIfItsOpen = (tag) => {
    const { extras, budget, upgrade } = this.state;

    switch (tag) {
      case 'BUDGET_ADJUDICATED':
        return true;
      case 'BUDGET_ROOM_PLAN':
        return inRoomPlanTest() && extras?.tables?.qtd > 0 && extras?.tables?.cost > 0 ? true : false;
      case 'BUDGET_CEREMONY':
        return extras?.ceremony?.length > 0 ? true : false;
      case 'BUDGET_STAFF':
        return extras?.staff?.length > 0 ? true : false;
      case 'BUDGET_FOOD':
        return extras?.food?.length > 0 ? true : false;
      case 'BUDGET_DRINK':
        return extras?.drinks?.length > 0 ? true : false;
      case 'BUDGET_DECORATION':
        return extras.decoration?.length > 0 ? true : false;
      case 'BUDGET_PARTY':
        return extras?.party?.length > 0 ? true : false;
      case 'BUDGET_UPGRADE':
        return upgrade ? true : false;
      case 'BUDGET_EXPENSES':  // 'BUDGET_OTHERS':
        const expenses = budget.filter(b => b.type === 'EXPENSE');
        return expenses?.length > 0 ? true : false;
      case 'BUDGET_OFFERS':
        const offers = budget.filter(b => b?.type === 'OFFER');
        return offers?.length > 0 ? true : false;
      case 'BUDGET_DISCOUNTS':
        const discounts = budget.filter(b => b?.type === 'DISCOUNT');
        return discounts?.length > 0 ? true : false;
      default:
        return false;
    }
  }

  getOpenPanels = () => {
    const { graph2 } = this.state;
    const openPanels = [];

    let stats = [...graph2];
    stats = stats.filter(f => f?.tag !== 'BUDGET_EXPENSES');
    for (let index = 0; index < stats.length; index++) {
      const section = stats[index];
      const toOpen = this.checkIfItsOpen(section?.tag);
      if (toOpen) openPanels.push(index?.toString())
    }

    if (budgetExpenses?.value > 0) {
      const toOpen = this.checkIfItsOpen(budgetExpenses?.tag);
      if (toOpen) {
        let index = stats?.length + budgetExpenses.index;
        openPanels.push(index?.toString());
      }
    }

    if (budgetDiscounts?.value > 0) {
      const toOpen = this.checkIfItsOpen(budgetDiscounts?.tag);
      if (toOpen) {
        let index = stats?.length + budgetDiscounts.index;
        openPanels.push(index?.toString());
      }
    }

    if (budgetOffers?.value > 0) {
      const toOpen = this.checkIfItsOpen(budgetOffers?.tag);
      if (toOpen) {
        let index = stats?.length + budgetOffers.index;
        openPanels.push(index?.toString());
      }
    }

    return openPanels;
  }


  render() {
    const { wedding } = this.props;
    const {
      ready,
      loading,
      openModal,
      edit,
      budget,
      graph1,
      graph2,
      total,
      drawer,
      openNotesConfirm,
      currentPaymentProof
    } = this.state;
    const { questionsPaymentMethods, paymentPhases, venueConfig, validatingBudget, percentageCalc, percentageCalc4, numGuests, updatingPercentage } = this.state;
    const openPanels = this.getOpenPanels();

    const mainColor = wedding?.wedding?.wedding_place === 1 ? '#f7c0b9' : wedding?.wedding?.wedding_place === 2 ? '#a3d2bb' : '#faf9f9';

    if (!ready) return <SpinLoading />;

    return (
      <Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Orçamento</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'default'}
              icon={'calendar'}
              text={'Validar Orçamento'}
              loading={validatingBudget}
              onClick={() => this.validateBudget()}
            />
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Novo registo'}
              onClick={() => this.openModal()}
            />
            <BaseButton
              type={'default'}
              icon={'read'}
              text={'Notas'}
              onClick={this.openNotes}
              notes={hasNotes(wedding.notes, 'BUDGET')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        {
          wedding?.wedding?.printed && <PrintedBar small={true} />
        }
        <BudgetContainer printed={wedding?.wedding?.printed}>
          <PageContainer buttons={2} printed={wedding?.wedding?.printed} notesOpen={drawer}>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <SectionTitle>Valor Total: {numberFormat(total)}€</SectionTitle>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <BudgetPercentage>
                  <label>Cálculo para Fase 3 (%): </label>
                  <div className='input-number'>
                    <NumberInput
                      min={0}
                      max={100}
                      onInput="validity.valid || (value = '')"
                      input={{
                        value: percentageCalc,
                        onChange: value => {
                          const val = value !== '' ?
                            Number.isInteger(value)
                              ? value > 100
                                ? 100
                                : value < 0
                                  ? 0
                                  : value
                              : parseInt(value.toString(), 10)
                            : '';
                          this.setState({ percentageCalc: val })
                        }
                      }}
                      meta={{
                        invalid: !percentageCalc || percentageCalc == '' || percentageCalc < 0 || percentageCalc > 100 ? true : false,
                        submitFailed: !percentageCalc || percentageCalc == '' || percentageCalc < 0 || percentageCalc > 100 ? true : false
                      }}
                      type="number"
                      placeholder="Insira a percentagem"
                    />
                  </div>
                  {paymentPhases?.length > 4 &&
                    <>
                      <label>Cálculo para Fase 4 (%): </label><div className='input-number'>
                        <NumberInput
                          min={0}
                          max={100}
                          onInput="validity.valid || (value = '')"
                          input={{
                            value: percentageCalc4,
                            onChange: value => {
                              const val = value !== '' ?
                                Number.isInteger(value)
                                  ? value > 100
                                    ? 100
                                    : value < 0
                                      ? 0
                                      : value
                                  : parseInt(value.toString(), 10)
                                : '';
                              this.setState({ percentageCalc4: val });
                            }
                          }}
                          meta={{
                            invalid: !percentageCalc4 || percentageCalc4 == '' || percentageCalc4 < 0 || percentageCalc4 > 100 ? true : false,
                            submitFailed: !percentageCalc4 || percentageCalc4 == '' || percentageCalc4 < 0 || percentageCalc4 > 100 ? true : false
                          }}
                          type="number"
                          placeholder="Insira a percentagem" />
                      </div>
                    </>
                  }
                  <BaseButton
                    type={'primary'}
                    icon={'save'}
                    disabled={!percentageCalc || percentageCalc == '' || percentageCalc < 0 || percentageCalc > 100}
                    text={'Guardar'}
                    onClick={() => this.updateBudgetPercentage()}
                  />
                </BudgetPercentage>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <BudgetGraph data={graph1} />
                <BudgetInfo>
                  <ul>
                    {wedding?.wedding && wedding?.wedding?.updatedBudget &&
                      <li className='updated line'>
                        <b>Atualizado em:</b>
                        <span>{moment.utc(wedding?.wedding?.updatedBudget).format('DD/MM/YYYY HH:mm')}</span>
                      </li>}
                    {venueConfig && venueConfig?.iban &&
                      <li className='iban line'>
                        <b>IBAN:</b>
                        <span>{venueConfig?.iban} {venueConfig?.swift || ''}</span>
                      </li>}
                    {paymentPhases && Array.isArray(paymentPhases) && paymentPhases.length > 0
                      && <React.Fragment>

                        {paymentPhases.map((p, index) => {
                          return <li key={'phase' + index} className='payment-phase line'>
                            <PaymentPhaseWithEmailContainer>
                              <div className="phase-info">
                                <div className='title'>
                                  <b>{p?.phase?.pt || ''} {p?.order || ''}: </b>
                                  <span>{getPhaseDate(p)}</span>
                                </div>
                                {p?.order > 2 && p?.paymentQuestion && <div className='subtitle'>
                                  {p?.paymentQuestion?.option?.title && <span>
                                    <Icon type='question-circle' />
                                    {p?.paymentQuestion?.option?.title?.pt || ''}
                                  </span>
                                  }
                                </div>}
                                <div className='price'>
                                  {p?.order == 3 && <span className='preview'>
                                    <b>Previsto de:</b>
                                    <CurrencyComponent value={p?.toPay || 0} />
                                    <b>a</b>
                                    <CurrencyComponent value={p?.totalMissing || 0} />
                                  </span>}

                                  {p?.order == 4 && paymentPhases.length > 4 && <span className='preview'>
                                    <b>Previsto de:</b>
                                    <CurrencyComponent value={p?.toPay || 0} />
                                    <b>a</b>
                                    <CurrencyComponent value={p?.totalMissing || 0} />
                                  </span>}

                                  {p?.order == 4 && paymentPhases.length == 4 && <span className='preview'>
                                    <b>Previsto:</b>
                                    <CurrencyComponent value={p?.toPay || 0} />
                                  </span>}

                                  {p?.order > 4 && <span className='preview'>
                                    <b>Previsto:</b>
                                    <CurrencyComponent value={p?.toPay || 0} />
                                  </span>}

                                  <span className='preview'><b>Pago:</b><CurrencyComponent value={p?.payed || 0} /></span>

                                  {p?.order == 3 && checkPreviousPhasesCompleted(p, paymentPhases) && <span style={{ color: getMissingValue(p) > 0 ? 'red' : 'inherit' }}>
                                    <b>Falta:</b>
                                    <CurrencyComponent value={getMissingValue(p)} />
                                  </span>}

                                  {p?.order == 4 && paymentPhases.length > 4 && checkPreviousPhasesCompleted(p, paymentPhases) && <span style={{ color: getMissingValue(p) > 0 ? 'red' : 'inherit' }}>
                                    <b>Falta:</b>
                                    <CurrencyComponent value={getMissingValue(p)} />
                                  </span>}

                                </div>
                              </div>
                              {p?.order == 1 && this.isPhaseAlreadyNotified(p?.order) && <PaymentPhaseWithEmailButton alreadyNotified={true} mainColor={mainColor}>
                                <Icon type="mail" />
                              </PaymentPhaseWithEmailButton>}
                              {p?.order == 1 && this.isPhaseComplete(p?.order) && !this.isPhaseAlreadyNotified(p?.order) && <Popconfirm
                                title="Deseja notificar o casal acerca da conclusão da fase?"
                                onConfirm={() => this.notifyCoupleAboutPhaseComplete(p?.order)}>
                                <PaymentPhaseWithEmailButton alreadyNotified={false} mainColor={mainColor}>
                                  <Icon type="mail" />
                                </PaymentPhaseWithEmailButton>
                              </Popconfirm>}
                            </PaymentPhaseWithEmailContainer>
                          </li>
                        })}
                      </React.Fragment>}
                  </ul>
                </BudgetInfo>
              </Col>
              <Col xs={12}>
                <BudgetGraph data={graph2} />
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col xs={12}>
                <SectionTitle subsection>Pagamentos Efetuados</SectionTitle>
                {budget
                  .filter(b => b.type === 'PAYMENT')
                  .map(register => (
                    <RegisterBoxPayment key={register._id} phaseConcluded={this.isPhaseComplete(register?.phase)} mainColor={mainColor}>
                      <Title>{register.title}</Title>
                      <Description>{register.description}</Description>
                      <Value>-{numberFormat(register.value)}€</Value>
                      <Date>
                        {moment.utc(register.date).format('DD/MM/YYYY')}
                      </Date>

                      {!register?.paymentConfirmed && <Popconfirm
                        title="Deseja notificar o casal acerca da aprovação do pagamento?"
                        onConfirm={() => this.notifyCoupleAboutPaymentApprove(register._id)}>
                        <OptionButton number={2}>
                          <Icon type="mail" />
                        </OptionButton>
                      </Popconfirm>}

                      <OptionButton
                        number={1}
                        onClick={() => this.openModal(register)}>
                        <Icon type="edit" />
                      </OptionButton>

                      <Popconfirm
                        title="Tem a certeza que quer remover este registo?"
                        onConfirm={() => this.deleteBudget(register._id)}>
                        <OptionButton number={0}>
                          <Icon type="delete" />
                        </OptionButton>
                      </Popconfirm>
                    </RegisterBoxPayment>
                  ))}
                <SectionTitle subsection>Valores a descontar (-)</SectionTitle>
                {budget
                  .filter(b => b?.type === 'DISCOUNT')
                  .map(register => (
                    <RegisterBox key={register._id}>
                      <Title>{register.title}</Title>
                      <Description>{register.description}</Description>
                      <Value>-{numberFormat(register.value)}€</Value>
                      <Date>
                        {moment.utc(register.date).format('DD/MM/YYYY')}
                      </Date>
                      <OptionButton
                        number={1}
                        onClick={() => this.openModal(register)}>
                        <Icon type="edit" />
                      </OptionButton>

                      <Popconfirm
                        title="Tem a certeza que quer remover este registo?"
                        onConfirm={() => this.deleteBudget(register._id)}>
                        <OptionButton number={0}>
                          <Icon type="delete" />
                        </OptionButton>
                      </Popconfirm>
                    </RegisterBox>
                  ))}
                <SectionTitle subsection>Valores a adicionar (+)</SectionTitle>
                {budget
                  .filter(b => b.type === 'EXPENSE')
                  .map(register => (
                    <RegisterBox key={register._id}>
                      <Title>{register.title}</Title>
                      <Description>{register.description}</Description>
                      <Value>+{numberFormat(register.value)}€</Value>
                      <Date>
                        {moment.utc(register.date).format('DD/MM/YYYY')}
                      </Date>
                      <OptionButton
                        number={1}
                        onClick={() => this.openModal(register)}>
                        <Icon type="edit" />
                      </OptionButton>

                      <Popconfirm
                        title="Tem a certeza que quer remover este registo?"
                        onConfirm={() => this.deleteBudget(register._id)}>
                        <OptionButton number={0}>
                          <Icon type="delete" />
                        </OptionButton>
                      </Popconfirm>
                    </RegisterBox>
                  ))}
                <SectionTitle subsection>Ofertas (-)</SectionTitle>
                {budget
                  .filter(b => b?.type === 'OFFER')
                  .map(register => (
                    <RegisterBox key={register._id}>
                      <Title>{register.title}</Title>
                      <Description>{register.description}</Description>
                      <Value>-{numberFormat(register.value)}€</Value>
                      <Date>
                        {moment.utc(register.date).format('DD/MM/YYYY')}
                      </Date>
                      <OptionButton
                        number={1}
                        onClick={() => this.openModal(register)}>
                        <Icon type="edit" />
                      </OptionButton>

                      <Popconfirm
                        title="Tem a certeza que quer remover este registo?"
                        onConfirm={() => this.deleteBudget(register._id)}>
                        <OptionButton number={0}>
                          <Icon type="delete" />
                        </OptionButton>
                      </Popconfirm>
                    </RegisterBox>
                  ))}
                <SectionTitle subsection>Comprovativos</SectionTitle>
                {budget
                  .filter(b => b?.type === 'PAYMENT_PROOF')
                  .map(register => (
                    <RegisterBox key={register._id}>
                      <Title>{register?.billing?.fullName} - {register?.billing?.identificationNumber}</Title>
                      <Description style={{ marginBottom: '20px' }}>{register?.description}</Description>
                      <DocumentSection key={register?.document?._id}>
                        {register?.document.map(file =>
                          <DivMarginTop value="5px">
                            <FileOption file={file} fileType='document' />
                          </DivMarginTop>
                        )}
                      </DocumentSection>
                      <Date>
                        {moment.utc(register.date).format('DD/MM/YYYY')}
                      </Date>
                      <OptionButton
                        number={1}
                        onClick={() => this.openModal(register)}>
                        <Icon type="edit" />
                      </OptionButton>

                      <Popconfirm
                        title="O ficheiro do recibo será eliminado. Deseja prosseguir?"
                        onConfirm={() => this.deleteBudget(register._id)}>
                        <OptionButton number={0}>
                          <Icon type="delete" />
                        </OptionButton>
                      </Popconfirm>
                    </RegisterBox>
                  ))}
              </Col>
              <Col xs={12}>
                <SectionTitle subsection>Resumo de Orçamento</SectionTitle>
                <CollapseContainer expandIconPosition="right" defaultActiveKey={openPanels}>
                  {graph2
                    .filter(f => f?.tag !== 'BUDGET_EXPENSES')
                    .map((section, index) => (
                      <PanelContainer
                        header={this.renderCollapseHeader(section)}
                        key={index}
                      >
                        {this.renderDetailedExpenses(section.tag)}
                      </PanelContainer>
                    ))}
                  {budgetExpenses?.value > 0 && <PanelContainer
                    header={this.renderCollapseHeader(budgetExpenses)}
                    key={graph2?.filter(f => f?.tag !== 'BUDGET_EXPENSES')?.length + budgetExpenses.index}>
                    {this.renderDetailedExpenses(budgetExpenses.tag)}
                  </PanelContainer>}
                  {budgetDiscounts?.value > 0 && <PanelContainer
                    header={this.renderCollapseHeader(budgetDiscounts)}
                    key={graph2?.filter(f => f?.tag !== 'BUDGET_EXPENSES')?.length + budgetDiscounts.index}>
                    {this.renderDetailedExpenses(budgetDiscounts.tag)}
                  </PanelContainer>}
                  {budgetOffers?.value > 0 && <PanelContainer
                    header={this.renderCollapseHeader(budgetOffers)}
                    key={graph2?.filter(f => f?.tag !== 'BUDGET_EXPENSES')?.length + budgetOffers.index}>
                    {this.renderDetailedExpenses(budgetOffers.tag)}
                  </PanelContainer>}
                </CollapseContainer>

                {/* {wedding.wedding.billings.length > 0 && (
                  <React.Fragment>
                    <SectionTitle subsection>Dados Faturação</SectionTitle>
                    {wedding.wedding.billings.map((billing, index) => (
                      <BillingBlock key={index} billing={billing} />
                    ))}
                  </React.Fragment>
                )} */}
              </Col>
            </Row>
          </PageContainer>
          <CommentsDrawerFixed
            open={drawer}
            loading={loading}
            onClose={this.checkBeforeCloseNotes}
            saveNotes={this.saveNotes}
            printed={wedding?.wedding?.printed}
          />
        </BudgetContainer>
        <BudgetModal
          open={openModal}
          edit={edit}
          loading={loading}
          onSubmit={this.onSubmit}
          closeModal={this.closeModal}
          initialValues={currentPaymentProof}
          wedding={wedding.wedding}
          budget={budget}
        />
        {/* <CommentsDrawer
          title="Notas De Orçamento"
          open={drawer}
          loading={loading}
          onClose={this.checkBeforeCloseNotes}
          saveNotes={this.saveNotes}
        /> */}
        <NotesConfirmModal
          open={openNotesConfirm}
          loading={loading}
          onSubmit={this.saveNotes}
          closeModal={() => { this.setState({ openNotesConfirm: false }) }}
          exitWithoutSaving={this.closeNotes}
        />
      </Fragment >
    );
  }
}

const mapStateToProps = state => ({
  wedding: state.wedding
});

const mapActionToProps = dispatch =>
  bindActionCreators({ saveNotesOnReducer: SaveNotes, AddWedding, ChangeAboutUs, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(BudgetPage);

